body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch; 
}
fieldset {
  border: 0;
}
.copyright-logo {
  width: 10%;
  height: 50%
}
.title-section {
  max-height: 475px;
  max-width: 1920px;
  overflow: hidden;
}
.image-container {
  position: relative;
  overflow: hidden;
}
.top-image {
  position: absolute;
  width: 100%;
  height: auto;
  margin-top: 1%;
}
.bottom-image {
  width: 100%;
  height: auto;
  margin-top: -1%;
}
.hand {
  position: absolute;
  width: 27%;
  margin-top: 15%;
  margin-left: 8%;
  visibility: hidden;
}
.hpe-logo {
  width: 40%;
  height: 100%;
  margin-left: 50%;
  transform: rotate(30deg);
}
.metal-logo {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.content-section-1 {
  height: 275px;
}
.content-section-2 {
  height: 300px;
}
.sword-image {
  width: 26%;
  margin-top: -10%;
  z-index: 1;
}
.skull-image {
  width: 35%;
  margin-top: -15%;
  z-index: 1;
}
.middle-image {
  width: 100%;
  height: auto;
  margin-top: -32%;
  z-index: 1;
}
.title-info-container {
  position: absolute;
  top: -5%;
}
.title-info-text {
  font-size: 14px !important;
}
.title-info-text-container {
  align-self: center;
  align-items: center;
}
.content-text-section-1 {
  font-size: 12px !important;
  z-index: 2;
}
.section-1-title {
  font-size: 20px !important;
  z-index: 2;
}
@media only screen and (min-width: 500px) {
  .title-section {
    max-height: 600px;
    height: 600px
  }
  .title-info-text-container {
    align-self: flex-start;
    align-items: flex-start;
  }
  .title-info-text {
    font-size: 16px !important;
    line-height: 10px
  }
  .title-info-container {
    width: 400px;
    transform: rotate(-30deg);
    left: 33%;
    top: 4%;
  }
  .metal-logo {
    width: 50%;
    margin-left: 0;
    margin-right: 0;
  }
  .hpe-logo {
    margin-left: 30%;
  }
  .hand {
    visibility: visible;
  }
  .title-info-text {
    font-size: 14px !important;
  }
  .content-text-section-1 {
    font-size: 14px !important;
  }
  .section-1-title {
    font-size: 30px !important;
  }
  .content-section-1 {
    height: 300px;
  }
  .content-section-2 {
    height: 300px;
  }
}

@media only screen and (min-width: 600px) {

  .title-info-container {
    width: 400px;
    top: 6%;
  }
  .title-info-text {
    font-size: 16px !important;
  }
  .content-section-1 {
    height: 350px;
  }
  .content-section-2 {
    height: 350px;
  }
}
@media only screen and (min-width: 700px) {
  .title-section {
    max-height: 650px;
  }
  .title-info-container {
    width: 600px;
    top: 3%;
  }
  .title-info-text {
    font-size: 16px !important;
  }
  .content-section-1 {
    height: 375px;
  }
  .content-section-2 {
    height: 350px;
  }
  .hpe-logo {
    width: 21%;
  }
}
@media only screen and (min-width: 850px) {
  .title-section {
    max-height: 750px;;
  }
  .title-info-container {
    width: 700px;
    top: -2%;
  }
  .title-info-text {
    font-size: 20px !important;
  }
  .content-text-section-1 {
    font-size: 20px !important;
  }
  .section-1-title {
    font-size: 40px !important;
  }
  .content-section-1 {
    height: 425px;
  }
  .content-section-2 {
    height: 400px;
  }
}
@media only screen and (min-width: 950px) {
  .title-section {
    min-height: 800px !important;
  }
  .hpe-logo {
    margin-left: 42%;
  }
  .title-section {
    max-height: 800px;
  }
  .title-info-container {
    width: 850px;
  }
  .title-info-text {
    font-size: 22px !important;
  }
  .content-section-1 {
    height: 450px;
  }
  .content-section-2 {
    height: 420px;
  }
}
@media only screen and (min-width: 1050px) {
  .title-section {
    min-height: 900px !important;
  }
  .title-section {
    max-height: 900px;
  }
  .title-info-container {
    width: 950px;
    top: 2%;
  }
  .title-info-text {
    font-size: 24px !important;
  }
  .content-text-section-1 {
    font-size: 30px !important;
    margin-bottom: 5px;
  }
  .section-1-title {
    font-size: 50px !important;
    margin-bottom: 40px !important;
  }
  .content-section-1 {
    height: 500px;
  }
  .content-section-2 {
    height: 475px;
  }
}
@media only screen and (min-width: 1200px) {
  .title-section {
    min-height: 1000px !important;
  }
  .title-info-text {
    font-size: 30px !important;
    margin-bottom: 5px;
  }
  .hpe-logo {
    margin-left: 50%;
    width: 31%;
  }
  .title-section {
    max-height: 1000px;
  }
  .title-info-container {
    width: 1000px;
    top: 2%;
  }
  .content-section-1 {
    height: 550px;
  }
  .content-section-2 {
    height: 500px;
  }
  .content-text-section-1 {
    font-size: 35px !important;
    margin-bottom: 8px;
  }
  .section-1-title {
    font-size: 50px !important;
    margin-bottom: 40px !important;
  }
}
@media only screen and (min-width: 1350px) {
  .hpe-logo {
    margin-left: 60%;
  }
  .title-section {
    min-height: 1100px !important;
    max-height: 1200px;
  }
  .title-info-container {
    width: 1200px;
    top: 0%;
  }
  .content-section-1 {
    height: 600px;
  }
  .content-section-2 {
    height: 525px;
  }
  .content-text-section-1 {
    font-size: 40px !important;
    margin-bottom: 10px;
  }
  .section-1-title {
    font-size: 60px !important;
  }
}
@media only screen and (min-width: 1500px) {
  .title-info-text {
    font-size: 35px !important;
    margin-bottom: 7px;
  }
  .hpe-logo {
    width: 40%;
  }
  .title-section {
    min-height: 1200px !important;
    max-height: 1500px;
  }
  .title-info-container {
    width: 1400px;
    top: -7%;
  }
  .content-section-1 {
    height: 650px;
  }
  .content-section-2 {
    height: 550px;
  }
  .content-text-section-1 {
    font-size: 45px !important;
    margin-bottom: 12px;
  }
}
@media only screen and (min-width: 1650px) {
  .title-info-container {
    top: -4%;
  }
  .title-section {
    min-height: 1300px !important;
  }
  .content-section-1 {
    height: 725px;
  }
  .content-section-2 {
    height: 600px;
  }
}
@media only screen and (min-width: 1800px) {
  .title-section {
    min-height: 1400px !important;
  }
  .content-section-1 {
    height: 800px;
  }
  .content-section-2 {
    height: 650px;
  }
  .title-info-container {
    width: 1800px;
    top: -8%;
  }
}
/* IE CSS */
.ie-title-text {
  font-size: 15px !important;
  line-height: 15px !important;
}
.ie-content-text {
  font-size: 13px !important;
  line-height: 12px !important;
}
.ie-content-text-title {
  font-size: 20px !important;
}
.ie-content {
  height: 300px;
}
@media only screen and (min-width: 550px) {
  .ie-title-text {
    font-size: 25px !important;
    line-height: 25px !important;
  }
  .ie-content-text {
    font-size: 22px !important;
    line-height: 26px !important;
  }
  .ie-content-text-title {
    font-size: 32px !important;
  }
  .ie-content {
    height: 475px;
  }
}